// single user print preview
const userPrintPreview = () =>
  import("@/pages/users/users-component/print-preview.vue");

// all user list print preview
const allUserPrintPreview = () =>
  import("@/pages/users/users-component/print-all-user.vue");

// report print preview
const printReport = () => import("@/pages/dossier/report/print.vue");

// 9th to 1st acr print preview
const ninthFormAcrPrint = () => import("@/pages/acr/ninth-form/print.vue");

// 10th to 12th acr print preview
const tenthFormAcrPrint = () => import("@/pages/acr/ten-to-twelve/print.vue");
const tenthFormAcrSelfPrint = () =>
  import("@/pages/acr/ten-to-twelve/print-self-acr.vue");

// 13 to 16th acr print preview
const thirteenToSixteenAcrPrint = () =>
  import("@/pages/acr/thirteen-to-sixteen/print.vue");
const thirteenToSixteenSelfAcrPrint = () =>
  import("@/pages/acr/thirteen-to-sixteen/print-self-acr.vue");

const userProfilePrint = () => import("@/pages/users/profile-print");

const printRoute = {
  children: [
    {
      path: "/user-print-preview/:id",
      name: "user-print-preview",
      component: userPrintPreview,
      meta: { requiredAuth: true },
    },
    {
      path: "/profile-print",
      name: "profile-print",
      component: userProfilePrint,
      meta: {
        requiredAuth: true,
        roles: [
          "admin",
          "user",
          "dosser",
          "hr",
          "applicant",
          "iro",
          "cro",
          "role",
          "reporter assign",
          "access log",
        ],
      },
    },
    {
      path: "/all-user-print-preview",
      name: "all-user-print-preview",
      component: allUserPrintPreview,
      meta: { requiredAuth: true },
    },

    {
      path: "/print-report",
      name: "report-print",
      component: printReport,
      meta: {
        requiredAuth: true,
        // role: "open",
      },
    },
    {
      path: "/ninth-form-acr-print/:id",
      name: "ninth-form-acr-print",
      component: ninthFormAcrPrint,
      meta: { requiredAuth: true },
    },
    {
      path: "/ninth-form-self-acr-print/:id",
      name: "ninth-form-self-acr-print",
      component: ninthFormAcrPrint,
      meta: { requiredAuth: true },
    },
    {
      path: "/tenth-to-twelve-form-acr-print/:id",
      name: "tenth-to-twelve-form-acr-print",
      component: tenthFormAcrPrint,
      meta: { requiredAuth: true },
    },
    {
      path: "/tenth-to-twelve-form-self-acr-print/:id",
      name: "tenth-to-twelve-form-self-acr-print",
      component: tenthFormAcrSelfPrint,
      meta: { requiredAuth: true },
    },
    {
      path: "/thirteen-to-sixteen-form-acr-print/:id",
      name: "thirteen-to-sixteen-form-acr-print",
      component: thirteenToSixteenAcrPrint,
      meta: { requiredAuth: true },
    },
    {
      path: "/thirteen-to-sixteen-form-self-acr-print/:id",
      name: "thirteen-to-sixteen-form-self-acr-print",
      component: thirteenToSixteenSelfAcrPrint,
      meta: { requiredAuth: true },
    },
    
  ],
};

export default printRoute;
