// role routes
const getRolePermission = () => import("@/pages/hr-settings/roles");
const viewUserRole = () =>
  import("@/pages/hr-settings/roles/view-user-role.vue");
const userRole = () => import("@/pages/hr-settings/roles/user-list");
// const User = () => import("../../pages/users/index");
import Body from "@/components/body";
const sections = {
  path: "/roles",
  component: Body,
  meta: { title: "Role", roles: ["hr", "admin", "role"] },
  children: [
    // section
    {
      path: "",
      name: "roles",
      component: getRolePermission,
      meta: { requiredAuth: true, roles: ["hr", "admin", "role"] },
    },
    {
      path: "/view-user-role/:id",
      name: "view-user-role",
      meta: { requiredAuth: true, roles: ["hr", "admin", "role"] },
      component: viewUserRole,
    },
    {
      path: "/user-role",
      name: "user-role",
      component: userRole,
      meta: { requiredAuth: true, roles: ["hr", "admin", "role"] },
    },
  ],
};

export default sections;
