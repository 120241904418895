<template>
  <router-link to="/">
    <img
      src="../../../assets/images/logo.png"
      alt=""
      class="img-fluid w-50 h-auto for-light"
    />
    <!-- <img
      class="img-fluid for-dark"
      src="../../../assets/images/ict-acr-images/auth-section/IctDivisionLogo.png"
      alt=""
    /> -->
    <!-- <side-bar-logo class="img-fluid for-light" /> -->
  </router-link>
</template>
<script>
import sideBarLogo from "./sidebarLogoSvg.vue";
export default {
  components: {
    sideBarLogo,
  },
  name: "Logo",
};
</script>
