import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";

/* Dashboards */
import Default from "../pages/dashboard/default";
const EmployeeHierarchy = () => import("@/pages/employee-hierarchy");
const AccessLog = () => import("@/pages/access-log");
const ErrorPages = () => import("@/pages/error/error404.vue");
const Error403Pages = () => import("@/pages/error/error403.vue");
/* Auth */
import login from "../auth/login";
import otp from "../auth/otp";
const userView = () => import("../pages/users/users-component/print.vue");

// acr
import ACRRoute from "./acr";
import usersRoute from "./users";
import designations from "./hr-settings/designations";
import sections from "./hr-settings/sections";
import wings from "./hr-settings/wings";
import newBranch from "./hr-settings/new-branch";
import configurations from "./hr-settings/configurations";
import roles from "./hr-settings/roles";
import salaryScale from "./hr-settings/salary-scale";

// dossier router
import dossierRoute from "./dossier";
// print router
import printRoute from "./print";

export const asyncRoutes = [
  {
    path: "/employee-hierarchy",
    component: Body,
    children: [
      {
        path: "",
        name: "employee-hierarchy",
        component: EmployeeHierarchy,
        meta: {
          requiredAuth: true,
          roles: ["dosser", "admin", "reporter assign"],
        },
      },
    ],
  },
  {
    path: "/access-log",
    component: Body,
    children: [
      {
        path: "",
        name: "access-log",
        component: AccessLog,
        meta: {
          requiredAuth: true,
          roles: ["admin", "hr","access log"],
        },
      },
    ],
  },
  ACRRoute,
  usersRoute,
  designations,
  sections,
  printRoute,
  roles,
  wings,
  newBranch,
  dossierRoute,
  configurations,
  salaryScale,
];
export const constantRoutes = [
  {
    path: "/redirect",
    component: Body,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/pages/redirect/index"),
      },
    ],
  },
  {
    path: "/auth-redirect",
    component: () => import("@/pages/redirect/auth-redirect"),
    hidden: true,
  },
  {
    path: "/",
    component: Body,

    children: [
      {
        path: "",
        name: "ড্যাশবোর্ড",
        component: Default,
        meta: {
          title: "RHD - ACR",
          // role: "open",
        },
      },
      {
        path: "/user-print/:id",
        name: "user-print",
        component: userView,
        meta: {
          requiredAuth: true,
          // role: "open",
        },
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login",
        component: login,
        meta: {
          title: " login | RHD - ACR",
        },
      },
      {
        path: "otp",
        name: "OTP",
        component: otp,
        meta: {
          title: " otp | RHD - ACR",
        },
      },
    ],
  },
  {
    path: '/error',
    redirect: 'noRedirect',
    name: 'ErrorPages',
    meta: {
      title: 'Error Pages',
      icon: '404'
    },
    children: [
      {
        path: '404',
        component: ErrorPages,
        name: 'Page404',
        meta: { title: '404', noCache: true }
      },
      {
        path: '403',
        component: Error403Pages,
        name: 'Page403',
        meta: { title: '403', noCache: true }
      }
    ]
  },

];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRoutes,
});

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes: constantRoutes,
  });

  router.value.matcher = newRouter.value.matcher; // reset router
}

// Function to add dynamic routes
export function addDynamicRoutes(routes) {
  routes.forEach((route) => {
    router.addRoute(route);
  });
}

export default router;
