import { getToken } from "@/utils/auth";
import axios from "axios";
import router from "@/router";


const service = axios.create({
  baseURL: "https://api-acr-rhd.inflack.net/",
});

service.interceptors.request.use(
  (config) => {
    (config.headers["Token"] = getToken()),
      (config.headers["Access-Control-Allow-Headers"] = "*"),
      (config.headers["Access-Control-Allow-Origin"] = "*"),
      (config.headers["Accept"] = "application/json"),
      (config.headers["Content-Type"] = "application/json"),
      (config.headers["Authorization"] = "Bearer " + getToken());

    return config;
  },
  (error) => {
    // do something with request error
    // console.log("error", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response;
    if (res.status === "error") {
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error.response.status === 403) {
      router.push("/error/403");
    }
    return Promise.reject(error);
  }
);

export default service;
