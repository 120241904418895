import language from "@/data/language.json"

const state = {
    langs : language.langs,
  
   
};

export default {
    namespaced: true,
    state,
}