import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/scss/app.scss";
import VueFeather from "vue-feather";
import store from "./store";
import Breadcrumbs from "./components/bread_crumbs";
import "bootstrap";
import settingPage from "./components/settingPage.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import bcard from "./components/b-card.vue";
import VueAnimateOnScroll from "vue3-animate-onscroll";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import Datepicker from "vue3-datepicker";
import VueApexCharts from "vue3-apexcharts";


import permission from "./directive/permission/index.js";
import './permission' // permission control
// import SocketPlugin from "./socketio.js";

import TreeFolder from "../src/pages/employee-hierarchy/components/tree-folder.vue";


createApp(App)
  .use(BootstrapVue3)
  .use(store)
  .use(VueSweetalert2)
  .use(VueAnimateOnScroll)
  .use(router)
  .use(permission)
  
  .component("settingPage", settingPage)
  .component("tree-folder", TreeFolder)
  .component("b-card", bcard)
  .use(PerfectScrollbar)
  .component("multiselect", Multiselect)
  .component(VueFeather.name, VueFeather)
  .component("Breadcrumbs", Breadcrumbs)
  .component("Datepicker", Datepicker)
  .component("apexchart", VueApexCharts)
  
  // .use(SocketPlugin)
  .mount("#app");
//   
