import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // get token from cookie
import { addDynamicRoutes } from './router'

const whiteList = ['/auth/login', '/auth/otp', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/auth/login' || to.path === '/auth/otp') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const user = await store.dispatch('userStore/getInfo')
          const Roles = user.Roles
          let roles = []
          if (Roles.length > 0) {
            roles = Roles.map((item) => {
              return item.name.replace(/_/g, " ").replace(/-/g, " ").toLowerCase()
            })
          }
          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          addDynamicRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('auth/resetToken')
          next(`/auth/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/auth/login?redirect=${to.path}`)
    }
  }
})

// if requested route is not in the list of routes, redirect to 404 page
router.beforeResolve((to, from, next) => {
  if (!router.hasRoute(to.name)) {
    next({ path: '/error/404' })
  }
  next()
})
